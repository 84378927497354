import { ICreateStripeCustomerRequest } from 'Colugo/interfaces/payment/ICreateStripeCustomerRequest';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import PaymentOperations from 'Colugo/operations/payment/PaymentOperations';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import ImprovedButton from 'components/shared/ImprovedButton';
import ImprovedRadioButton from 'components/shared/ImprovedRadioButton';
import { PaymentContext } from 'provider/payment/PaymentProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { getStripePriceDetails } from 'utility/payment/paymentUtils';
import { SubscriptionType } from 'Colugo/interfaces/subscriptions/ISubscription';

const paymentOperations = container.resolve(PaymentOperations);

type PlanPaymentTypeRouteParams = {
  listingId: string;
};

export enum PlansLookupKey {
  MonthlyPlus = 'MonthlyPlus',
  YearlyPlus = 'YearlyPlus',
  MonthlyPremium = 'MonthlyPremium',
  YearlyPremium = 'YearlyPremium'
}

export enum PlansFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export interface IPlanDetails {
  price: number;
  frequency: string;
}

function PlanPaymentType() {
  const navigate = useNavigate();
  const { listingId } = useParams<PlanPaymentTypeRouteParams>();
  const [searchParams] = useSearchParams();
  const { user, mutateUser } = useContext(UserContext);
  const { stripePrices } = useContext(PaymentContext);
  const { errorToast } = useErrorToast();
  const plan = searchParams.get('plan');
  const [selectedPlanFrequency, setSelectedPlanFrequency] = useState<string>(
    PlansFrequency.Monthly
  );
  const [isCreatingCustomer, setIsCreatingCustomer] = useState<boolean>(false);

  async function createStripeCustomerAsync() {
    if (!user) {
      errorToast(`Error retrieving user information, please try again later.`);
      return;
    }
    if (!user.stripeCustomerId) {
      const createStripeCustomerRequest: ICreateStripeCustomerRequest = {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`
      };
      setIsCreatingCustomer(true);
      const { data, error } = await paymentOperations.createStripeCustomerAsync(
        createStripeCustomerRequest
      );

      if (!data || error) {
        errorToast(
          `Error creating customer information, please try again later.`
        );
        setIsCreatingCustomer(false);
        return;
      }

      mutateUser({ ...user, stripeCustomerId: data.stripeCustomer.id });
      setIsCreatingCustomer(false);
    }
    navigate(
      `${
        PuffinRoutes.PlanOrderSummary.split(':')[0] + listingId
      }?plan=${plan}&frequency=${selectedPlanFrequency}`
    );
  }

  function getPlanDetails() {
    let planDetails: IPlanDetails[];
    switch (plan) {
      case SubscriptionType.Essential:
        break;
      case SubscriptionType.Plus:
        planDetails = [
          {
            price:
              getStripePriceDetails(stripePrices, PlansLookupKey.MonthlyPlus)
                ?.unitAmount ?? 0,
            frequency: PlansFrequency.Monthly
          },
          {
            price:
              getStripePriceDetails(stripePrices, PlansLookupKey.YearlyPlus)
                ?.unitAmount ?? 0,
            frequency: PlansFrequency.Yearly
          }
        ];

        return planDetails;
      case SubscriptionType.Premium:
        planDetails = [
          {
            price:
              getStripePriceDetails(stripePrices, PlansLookupKey.MonthlyPremium)
                ?.unitAmount ?? 0,
            frequency: PlansFrequency.Monthly
          },
          {
            price:
              getStripePriceDetails(stripePrices, PlansLookupKey.YearlyPremium)
                ?.unitAmount ?? 0,
            frequency: PlansFrequency.Yearly
          }
        ];

        return planDetails;
    }
  }

  const allPlansDetails = getPlanDetails();

  return (
    <div className="flex flex-col h-screen bg-theme1">
      <CustomFormHeader
        title={`Upgrade To ${
          selectedPlanFrequency ? selectedPlanFrequency : ''
        }  ${plan}`}
        onPressClose={() => navigate(-1)}
      />
      <div className="mt-8 ml-auto mr-auto" style={{ width: '714px' }}>
        <h1 className="heading-bold-m text-primaryDark"> Plan Payment Type </h1>
        <p className="heading-regular-sm text-onSurfaceVariant mt-2">
          Step Instructions
        </p>
        <div className="mt-6">
          {allPlansDetails &&
            allPlansDetails.map((ap, i) => (
              <div
                className="bg-surface border border-outline rounded-xl flex flex-row px-4 py-7 justify-between mb-4"
                key={i}
              >
                <div className="flex flex-row items-center">
                  <div>
                    <ImprovedRadioButton
                      onChange={() => {
                        setSelectedPlanFrequency(ap.frequency);
                      }}
                      checked={selectedPlanFrequency === ap.frequency}
                      id="entityDescription"
                    />
                  </div>
                  <p className="ml-4 heading-semibold-m text-onSurface">
                    {ap.frequency}
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="heading-semibold-m text-right text-onSurface">
                    {`£${ap.price} p/m`}
                  </div>
                  <div className="heading-regular-av text-onSurface mt-1">
                    {`£${ap.price} Billed ${ap.frequency}`}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-end">
          <div style={{ minWidth: '128px' }}>
            <ImprovedButton
              onClick={async () => await createStripeCustomerAsync()}
              isLoading={isCreatingCustomer}
              size="lg"
              className={`text-lg px-8 py-4 justify-center flex items-center min-w-full w-32`}
              skin={'primary'}
            >
              <div className="heading-semibold-av text-white">Confirm</div>
            </ImprovedButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanPaymentType;
