import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IStripePrice } from 'Colugo/interfaces/payment/IStripePrice';
import { useReqListStripePricesAsync } from 'Colugo/operations/payment/PaymentOperations';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export type PaymentContextType = {
  stripePrices: IStripePrice[] | undefined;
};

export const PaymentContext = React.createContext<PaymentContextType>({
  stripePrices: undefined
});

function PaymentProvider(props: Props) {
  const { children } = props;

  const {
    data: prices,
    isLoading,
    isError: isPricesError
  } = useReqListStripePricesAsync();

  if (isLoading) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />;
      </div>
    );
  }

  if (isPricesError) {
    return (
      <ErrorPage>
        <span>{'Unable to load prices from server.'}</span>
      </ErrorPage>
    );
  }
  return (
    <PaymentContext.Provider
      value={{
        stripePrices: prices
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

export default PaymentProvider;
