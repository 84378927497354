import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import AnalyticsOperations from 'Colugo/operations/analytics/AnalyticsOperations';
import AnalyticsSnapshot from 'components/dashboard/AnalyticsSnapshot';
import ImprovedFlyoutMenu, {
  FlyoutMenuItem
} from 'components/shared/ImprovedFlyoutMenu';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import Analytics from './Analytics';

const analyticsOperations = container.resolve(AnalyticsOperations);

export const dateRangeFlyoutMenuItems = [
  { label: '7 Days', value: '7', xSpaceBetween: 892 },
  { label: '14 Days', value: '14', xSpaceBetween: 836 },
  { label: '30 Days', value: '30', xSpaceBetween: 806 },
  { label: '2 Months', value: '60', xSpaceBetween: 793 },
  { label: '6 Months', value: '183', xSpaceBetween: 784 }
];

export function getUpdateDiff(timeComputed: number | undefined) {
  const fetchDate = new Date(timeComputed || '');

  const now = new Date();
  const diffInMilliseconds = now.getTime() - fetchDate.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  if (diffInMinutes > 1440) {
    const diffInDays = Math.floor(diffInMinutes / 1440);
    return !isNaN(diffInDays)
      ? `Updated ${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`
      : '';
  }
  if (diffInMinutes > 59) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return !isNaN(diffInHours)
      ? `Updated ${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`
      : '';
  }
  return !isNaN(diffInMinutes)
    ? `Updated ${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`
    : '';
}

function BusinessAnalytics() {
  const [selectedNOfDaysBusiness, setSelectedNOfDaysBusiness] =
    useState<FlyoutMenuItem>(dateRangeFlyoutMenuItems[0]);
  const [selectedNOfDaysPromotion, setSelectedNOfDaysPromotion] =
    useState<FlyoutMenuItem>(dateRangeFlyoutMenuItems[0]);
  const [businessAnalytics, setBusinessAnalytics] = useState<
    IAnalytics | undefined
  >();
  const [promotionAnalytics, setPromotionAnalytics] = useState<
    IAnalytics | undefined
  >();
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(false);
  const [isPromotionLoading, setIsPromotionLoading] = useState<boolean>(false);

  const { listing } = useContext(ManagerContext);

  const promotionFlyoutMenuItems: FlyoutMenuItem[] =
    listing?.promotions?.map(
      (p) =>
        ({
          label: p.title,
          value: p.id
        } as FlyoutMenuItem)
    ) || [];

  const [selectedPromotion, setSelectedPromotion] = useState<FlyoutMenuItem>(
    promotionFlyoutMenuItems[0]
  );

  async function getBusinessAnalyticsAsync(fmi: FlyoutMenuItem) {
    setSelectedNOfDaysBusiness(fmi);
    setIsBusinessLoading(true);
    const { data: localAnalytics, error } =
      await analyticsOperations.getAnalyticsAsync(listing?.id, fmi.value);
    setIsBusinessLoading(false);
    if (error || !localAnalytics) {
      toast.error('Unable to fetch analytics, please refresh and try again.');
      return;
    }
    setBusinessAnalytics(localAnalytics);
  }

  const getPromotionAnalyticsAsync = useCallback(
    async (fmiPromotion: FlyoutMenuItem, fmiDate: FlyoutMenuItem) => {
      if (!fmiPromotion?.value) {
        return;
      }
      setIsPromotionLoading(true);
      const { data: localAnalytics, error } =
        await analyticsOperations.getAnalyticsAsync(
          fmiPromotion.value,
          fmiDate.value
        );
      setIsPromotionLoading(false);
      if (error || !localAnalytics) {
        toast.error('Unable to fetch analytics, please try again.');
        return;
      }
      setPromotionAnalytics(localAnalytics);
    },
    []
  );

  useEffect(() => {
    getPromotionAnalyticsAsync(selectedPromotion, selectedNOfDaysPromotion);
  }, [selectedPromotion, selectedNOfDaysPromotion, getPromotionAnalyticsAsync]);

  if (!listing?.analytics) {
    return <Spinner />;
  }

  const businessDiff = getUpdateDiff(
    businessAnalytics?.timeComputed || listing?.analytics?.timeComputed
  );
  const promotionsDiff = getUpdateDiff(promotionAnalytics?.timeComputed);

  const topBusinessComponent = (
    <div className="flex flex-row py-4 px-6 border-b border-outline font-inter">
      <div className="text-xl font-bold mt-3">Overall Analytics</div>
      {isBusinessLoading ? (
        <div className="mr-auto ml-3 pt-3">
          <Spinner />
        </div>
      ) : (
        <span className="mt-4 ml-4 pt-0.5 text-sm text-onSurfaceVariant">
          {businessDiff}
        </span>
      )}
      <div className="flex-grow" />
      <ImprovedFlyoutMenu
        flyoutMenuItems={dateRangeFlyoutMenuItems}
        onChange={getBusinessAnalyticsAsync}
        selectedItem={selectedNOfDaysBusiness}
        wrapperClassName="rounded-lg"
      />
    </div>
  );

  const topPromotionComponent = (
    <div className="flex flex-row py-4 px-6 border-b border-outline font-inter">
      <div className="text-xl font-bold mt-3">Promotions</div>
      {isPromotionLoading ? (
        <div className="mr-auto ml-3 pt-3">
          <Spinner />
        </div>
      ) : (
        <span className="mt-4 ml-4 pt-0.5 text-sm text-onSurfaceVariant">
          {promotionsDiff}
        </span>
      )}
      <div className="flex-grow" />
      <ImprovedFlyoutMenu
        flyoutMenuItems={promotionFlyoutMenuItems}
        onChange={(fmiPromotion) => setSelectedPromotion(fmiPromotion)}
        selectedItem={selectedPromotion || { label: 'Select a promotion' }}
        wrapperClassName="rounded-lg"
      />
      <div className="w-4" />
      <ImprovedFlyoutMenu
        flyoutMenuItems={dateRangeFlyoutMenuItems}
        onChange={(fmiDate) => setSelectedNOfDaysPromotion(fmiDate)}
        selectedItem={selectedNOfDaysPromotion}
        wrapperClassName="rounded-lg"
      />
    </div>
  );

  return (
    <div className="flex flex-row w-full overflow-y-auto">
      <div className="mt-10 mx-auto">
        <div className="text-2xl font-bold">Analytics</div>
        <AnalyticsSnapshot
          analytics={businessAnalytics ?? listing.analytics}
          topComponent={topBusinessComponent}
          nOfDays={Number(selectedNOfDaysBusiness.value)}
        />
        <Analytics listing={listing} title={'Business Listing'} />
        {listing.events && (
          <Analytics events={listing.events} title={'Events'} />
        )}
        <AnalyticsSnapshot
          analytics={promotionAnalytics}
          topComponent={topPromotionComponent}
          nOfDays={Number(selectedNOfDaysPromotion.value)}
          isWithCollected={false}
        />
        <div className="h-10" />
      </div>
    </div>
  );
}

export default BusinessAnalytics;
