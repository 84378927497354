//TODO: to be updated when designs are ready (https://jirabindystreet.atlassian.net/browse/APP-1835)
function PaymentSuccess() {
  return (
    <div className="flex h-full w-full justify-center items-center bg-green">
      <h1>Payment Success</h1>
    </div>
  );
}

export default PaymentSuccess;
