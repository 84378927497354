import { ICreateCheckoutSessionRequest } from 'Colugo/interfaces/payment/ICreateCheckoutSessionRequest';
import {
  ISubscription,
  SubscriptionType
} from 'Colugo/interfaces/subscriptions/ISubscription';
import PaymentOperations from 'Colugo/operations/payment/PaymentOperations';
import SubscriptionOperations from 'Colugo/operations/subscription/SubscriptionOperations';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import ImprovedButton from 'components/shared/ImprovedButton';
import { ManagerContext } from 'provider/manager/managerProvider';
import { PaymentContext } from 'provider/payment/PaymentProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { formatAddress } from 'utility/general/formatHelpers';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { getStripePriceDetails } from 'utility/payment/paymentUtils';

const paymentOperations = container.resolve(PaymentOperations);
const subscriptionOperations = container.resolve(SubscriptionOperations);

type PlanPaymentTypeRouteParams = {
  listingId: string;
};

function PlanOrderSummary() {
  const navigate = useNavigate();
  const { listingId } = useParams<PlanPaymentTypeRouteParams>();
  const [searchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const { errorToast } = useErrorToast();
  const plan = searchParams.get('plan');
  const frequency = searchParams.get('frequency');
  const { stripePrices } = useContext(PaymentContext);
  const { businessOwnsClaimsRequests } = useContext(ManagerContext);
  const [isCreatingCheckoutSession, setIsCreatingCheckoutSession] =
    useState<boolean>(false);

  const listing = businessOwnsClaimsRequests.owns.find(
    (br) => br.id === listingId
  );
  const planLookupKey = `${frequency}${plan}`;
  const planPrice =
    stripePrices &&
    getStripePriceDetails(stripePrices, planLookupKey)?.unitAmount;
  const planPriceId = getStripePriceDetails(stripePrices, planLookupKey)?.id;

  async function validateSubscriptionAsync() {
    if (!listing) {
      errorToast(`Error retrieving listing, please try again later.`);
      return;
    }

    const subscription: ISubscription = {
      listing: listing
    };
    const { error } = await subscriptionOperations.validateAsync(subscription);
    if (error) {
      errorToast(
        `Error validating subscription, subscription already exists for this business.`
      );
      return false;
    }
    return true;
  }

  async function createCheckoutSessionAsync() {
    if (
      !planPriceId ||
      !user ||
      !user.stripeCustomerId ||
      !listingId ||
      !listing?.name ||
      !plan
    ) {
      errorToast(`Error retrieving required details, please try again later.`);
      return;
    }

    const isValidationSuccesful = await validateSubscriptionAsync();

    if (!isValidationSuccesful) {
      return;
    }

    const createCheckoutSessionRequest: ICreateCheckoutSessionRequest = {
      priceId: planPriceId,
      customerId: user.stripeCustomerId,
      listingId: listingId,
      listingName: listing.name,
      subscriptionType: SubscriptionType[plan as keyof typeof SubscriptionType]
    };

    setIsCreatingCheckoutSession(true);
    const { data: checkoutSessionUrl, error } =
      await paymentOperations.createStripeCheckoutSessionAsync(
        createCheckoutSessionRequest
      );

    if (!checkoutSessionUrl || error) {
      setIsCreatingCheckoutSession(false);

      errorToast(`Error starting checkout session, please try again later.`);
      return;
    }

    setIsCreatingCheckoutSession(false);
    window.location.href = checkoutSessionUrl;
  }

  return (
    <div className="flex flex-col h-screen bg-theme1">
      <CustomFormHeader
        title={`Upgrade To ${frequency ? frequency : ''}  ${plan}`}
        onPressClose={() => navigate(-1)}
      />
      <div className="mt-8 ml-auto mr-auto" style={{ width: '714px' }}>
        <h1 className="heading-bold-m text-primaryDark"> Order Summary </h1>
        <p className="heading-regular-sm text-onSurfaceVariant mt-2">
          Step Instructions
        </p>
        <div className="mt-6">
          <div className="bg-surface border border-outline rounded-xl flex flex-col mb-4">
            <div className="flex flex-row p-4 items-center">
              <div>
                {listing && (
                  <img
                    src={listing.images![0] ?? ''}
                    alt="HeroImage"
                    className="rounded-lg"
                    style={{
                      objectFit: 'cover',
                      width: '80px',
                      height: '80px'
                    }}
                  />
                )}
              </div>
              <div className="flex flex-col ml-4">
                <p className="heading-bold-m text-onSurface">{listing?.name}</p>
                {listing?.address && (
                  <p className="mt-2 heading-regular-av text-onSurfaceVariant">
                    {formatAddress(listing?.address!)}
                  </p>
                )}
              </div>
            </div>
            <div
              className="flex flex-row justify-between w-full border-t border-outline"
              style={{ borderRadius: '0px 0px 12px 12px' }}
            >
              <div className="p-4 flex flex-row justify-between w-full">
                <div className="flex flex-row items-center">
                  <p className="heading-semibold-m text-onSurface">
                    {plan} Plan
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="heading-semibold-m text-right text-onSurface">
                    {`£${planPrice} p/m`}
                  </div>
                  <div className="heading-regular-av text-onSurface mt-1">
                    {`£${planPrice} Billed ${frequency}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <ImprovedButton
            onClick={() => navigate(-1)}
            isLoading={false}
            size="lg"
            className={`text-lg px-8 py-4`}
            skin={'lowRank'}
          >
            <div className="heading-semibold-av text-black">Back</div>
          </ImprovedButton>
          <div>
            <ImprovedButton
              onClick={async () => await createCheckoutSessionAsync()}
              isLoading={isCreatingCheckoutSession}
              size="lg"
              className={`text-lg px-8 py-4 justify-center flex items-center w-full`}
              skin={'primary'}
            >
              <div className="heading-semibold-av text-white">{`Pay £${planPrice}.00 GBP now`}</div>
            </ImprovedButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanOrderSummary;
