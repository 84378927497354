//TODO: to be updated when designs are ready (https://jirabindystreet.atlassian.net/browse/APP-1835)
function PaymentCanceled() {
  return (
    <div className="flex h-full w-full justify-center items-center bg-primary">
      <h1>Payment Canceled</h1>
    </div>
  );
}

export default PaymentCanceled;
