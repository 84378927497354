import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import {
  IEventVideoEntity,
  IListingVideoEntity,
  IVideo
} from 'Colugo/interfaces/video/IVideo';
import VideoOperations from 'Colugo/operations/video/VideoOperations';
import ImprovedInput from 'components/shared/ImprovedInput';
import ImprovedRadioButton from 'components/shared/ImprovedRadioButton';
import ImprovedTextArea from 'components/shared/ImprovedTextarea';
import VideoUploaderInput from 'components/shared/VideoUploaderInput';
import { ReactComponent as InfoIcon } from 'images/dashboard/info.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { tryAgainLaterText } from 'utility/constants/constants';
import { uploadFileOnDrop } from 'utility/general/fileUploadOnDrop';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { maxDescriptionLength, maxNameLength } from './GeneralSettings';

const videoOperations = container.resolve(VideoOperations);

interface IVideoOptions {
  isCustomTitle: boolean;
  isCustomDescription: boolean;
}

enum RadioButtonOption {
  Name,
  Description
}
type Props<T extends IListing | IEvent> = {
  localEntity: T;
  mutateEntityVideo(video: IVideo): void;
  deleteVideoAsync: () => Promise<void>;
  entityType: BlockEntityType;
  hasAccess: boolean;
};

function VideoSettings<T extends IListing | IEvent>(props: Props<T>) {
  const {
    localEntity,
    mutateEntityVideo,
    deleteVideoAsync,
    entityType,
    hasAccess
  } = props;
  const { errorToast } = useErrorToast();
  const navigate = useNavigate();
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [localVideo, setLocalVideo] = useState<IVideo | undefined>(
    localEntity.video ?? undefined
  );
  const [videoOptions, setVideoOptions] = useState<IVideoOptions>({
    isCustomTitle: !!localEntity.video?.title,
    isCustomDescription: !!localEntity.video?.caption
  });

  useEffect(() => {
    if (localEntity.video) {
      setLocalVideo(localEntity.video);
    }
  }, [localEntity.video]);

  const onDropFile = uploadFileOnDrop(
    createVideoAsync,
    setIsLoadingVideo,
    setProgress,
    true
  );

  function getVideoEntity() {
    let videoEntity: IListingVideoEntity | IEventVideoEntity | undefined;
    switch (entityType) {
      case BlockEntityType.Listing:
        videoEntity = {
          $type: 'Dippy.Models.Videos.ListingVideoEntity, Dippy.Models',
          entity: { id: localEntity.id! }
        } as IListingVideoEntity;
        return videoEntity;
      case BlockEntityType.Event:
        videoEntity = {
          $type: 'Dippy.Models.Videos.EventVideoEntity, Dippy.Models',
          entity: { id: localEntity.id! }
        } as IEventVideoEntity;
        return videoEntity;
      default:
        break;
    }
    return videoEntity;
  }

  async function createVideoAsync(videoUrl: string) {
    const videoEntity = getVideoEntity();
    if (!videoEntity) {
      errorToast(`No entity being attached, ${tryAgainLaterText}`);
      return;
    }
    const videoToCreate: IVideo = {
      url: videoUrl,
      entities: [videoEntity]
    };

    const { data: createdVideo, error } = await videoOperations.createAsync(
      videoToCreate
    );

    if (error) {
      errorToast(`Failed to create video, ${tryAgainLaterText}`);
      return;
    }

    mutateEntityVideo(createdVideo);
  }

  async function updateVideoAsync(videoUpdate: IVideo) {
    if (!videoUpdate) {
      errorToast(`No video to update, ${tryAgainLaterText}`);
      return;
    }

    const videoEntity = getVideoEntity();
    if (!videoEntity) {
      errorToast(`No entity being attached, ${tryAgainLaterText}`);
      return;
    }

    const videoToUpdate: IVideo = {
      ...videoUpdate,
      entities: [videoEntity]!
    };

    const { error } = await videoOperations.updateAsync(
      videoToUpdate.id!,
      videoToUpdate
    );

    if (error) {
      errorToast(`Failed to update video, ${tryAgainLaterText}`);
      return;
    }

    const { entities, ...videoWithoutEntity } = videoToUpdate;
    mutateEntityVideo(videoWithoutEntity);
  }

  async function handleUploadVideoAsync(video: File) {
    setIsLoadingVideo(true);
    await onDropFile([video]);
    setIsLoadingVideo(false);
  }

  interface IUpdateVideoField {
    title?: string;
    caption?: string;
  }

  async function onSelectEntityAsync(option: RadioButtonOption) {
    switch (option) {
      case RadioButtonOption.Name:
        await updateVideoFieldAsync(false, { title: '' }, option);
        break;
      case RadioButtonOption.Description:
        await updateVideoFieldAsync(false, { caption: '' }, option);
        break;
      default:
        break;
    }
  }

  async function updateVideoFieldAsync(
    isCustom: boolean,
    updateField: IUpdateVideoField,
    option: RadioButtonOption
  ) {
    if (option === RadioButtonOption.Name) {
      setVideoOptions({
        ...videoOptions,
        isCustomTitle: isCustom
      });
    } else {
      setVideoOptions({
        ...videoOptions,
        isCustomDescription: isCustom
      });
    }
    setLocalVideo({ ...localVideo, ...updateField });
    await updateVideoAsync({
      ...localVideo,
      ...updateField
    });
  }

  const entityName =
    entityType === BlockEntityType.Event
      ? 'event'
      : entityType === BlockEntityType.Listing
      ? 'business'
      : '';

  return (
    <div className="flex flex-col w-full">
      <div className="font-inter text-2xl font-bold">Video</div>

      <div className="flex flex-row w-full relative">
        <div className="flex-row border-r-2" style={{ width: '85%' }}>
          <>
            {hasAccess ? (
              <>
                <div className="flex flex-row mt-6">
                  <VideoUploaderInput
                    onUploadComplete={(videoFile) => {
                      handleUploadVideoAsync(videoFile);
                    }}
                    progress={progress}
                    isLoading={isLoadingVideo}
                    video={localEntity.video}
                    videoName={localEntity.name}
                    onClickDelete={async () => {
                      await deleteVideoAsync();
                      setLocalVideo(undefined);
                      setVideoOptions({
                        isCustomTitle: false,
                        isCustomDescription: false
                      });
                    }}
                  />

                  {localEntity.video && (
                    <div className="flex flex-col px-6 w-full">
                      <div className="flex flex-col">
                        <div>
                          <div className="font-inter text-base font-bold text-onSurface">
                            Video Title
                          </div>
                          <div className="flex flex-row mt-2">
                            <div className="flex flex-row">
                              <ImprovedRadioButton
                                onChange={async () =>
                                  onSelectEntityAsync(RadioButtonOption.Name)
                                }
                                checked={!videoOptions.isCustomTitle}
                                id="EntityName"
                              />
                              <p className="ml-2">
                                {entityName.charAt(0).toUpperCase() +
                                  entityName.slice(1)}{' '}
                                Name
                              </p>
                            </div>
                            <div className="flex flex-row ml-4">
                              <ImprovedRadioButton
                                onChange={() =>
                                  setVideoOptions({
                                    ...videoOptions,
                                    isCustomTitle: true
                                  })
                                }
                                checked={!!videoOptions.isCustomTitle}
                                id="customTitle"
                              />
                              <p className="ml-2">Custom Title</p>
                            </div>
                          </div>

                          {videoOptions.isCustomTitle && (
                            <div className="mt-2">
                              <ImprovedInput
                                name={'title'}
                                className="h-full focus:outline-none"
                                wrapperClassName="border rounded-lg bg-white flex-row items-center focus:border-onSurface px-6"
                                maxCharacterCount={maxNameLength}
                                onChange={(e) => {
                                  setLocalVideo({
                                    ...localVideo,
                                    title: e.currentTarget.value
                                  });
                                }}
                                value={localVideo?.title}
                                labelClassName={
                                  'heading-semibold-av-onSurfaceVariant'
                                }
                                error={
                                  !localVideo?.title &&
                                  videoOptions.isCustomTitle
                                    ? 'Please provide a custom title'
                                    : ''
                                }
                                isError={
                                  !localVideo?.title &&
                                  videoOptions.isCustomTitle
                                }
                                placeHolder={'Enter custom title'}
                                inputContainerStyle={{ height: '54px' }}
                                onBlurWithinCharacterLimit={async (e) => {
                                  await updateVideoAsync({
                                    ...localVideo,
                                    title: e.currentTarget.value
                                  });
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="mt-6">
                          <div className="font-inter text-base font-bold text-onSurface ">
                            Video Description
                          </div>
                          <div className="flex flex-row mt-2">
                            <div className="flex flex-row">
                              <ImprovedRadioButton
                                onChange={async () =>
                                  onSelectEntityAsync(
                                    RadioButtonOption.Description
                                  )
                                }
                                checked={!videoOptions.isCustomDescription}
                                id="entityDescription"
                              />
                              <p className="ml-2">
                                {entityName.charAt(0).toUpperCase() +
                                  entityName.slice(1)}{' '}
                                Description
                              </p>
                            </div>
                            <div className="flex flex-row ml-4">
                              <ImprovedRadioButton
                                onChange={() =>
                                  setVideoOptions({
                                    ...videoOptions,
                                    isCustomDescription: true
                                  })
                                }
                                checked={!!videoOptions.isCustomDescription}
                                id="customTitle"
                              />
                              <p className="ml-2">Custom Description</p>
                            </div>
                          </div>
                          {videoOptions.isCustomDescription && (
                            <div className="mt-2">
                              <ImprovedTextArea
                                name={'caption'}
                                className="h-full py-4 px-4 focus:outline-none"
                                wrapperClassName="border rounded-lg bg-white flex-row items-center focus:border-onSurface px-2"
                                maxCharacterCount={maxDescriptionLength}
                                value={localVideo?.caption}
                                onChange={(e) => {
                                  setLocalVideo({
                                    ...localVideo,
                                    caption: e.currentTarget.value
                                  });
                                }}
                                labelClassName={
                                  'heading-semibold-av-onSurfaceVariant'
                                }
                                placeHolder={
                                  'Enter a short description of your business'
                                }
                                error={
                                  !localVideo?.caption &&
                                  videoOptions.isCustomDescription
                                    ? 'Please provide a custom description'
                                    : ''
                                }
                                isError={
                                  !localVideo?.caption &&
                                  videoOptions.isCustomDescription
                                }
                                onBlurWithinCharacterLimit={async (e) => {
                                  await updateVideoAsync({
                                    ...localVideo,
                                    caption: e.currentTarget.value
                                  });
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="mt-8 bg-dimNavyConatiner border border-outline w-full text-onSurface h-11 px-4 py-2 rounded-lg flex items-center gap-3">
                <InfoIcon />
                <p className="heading-regular-av">
                  Upgrade to{' '}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => navigate(PuffinRoutes.UpdateBusinessPlan)}
                  >
                    Ember Plus or Premium{' '}
                  </span>
                  to upload a business video.
                </p>
              </div>
            )}
          </>
        </div>
        <div
          className="ml-6 text-sm font-inter font-normal"
          style={{ width: '247px' }}
        >
          <div>Add a video to make your {entityName} even more enticing.</div>
          <div className="mt-4">
            Use your {entityName.charAt(0).toUpperCase() + entityName.slice(1)}{' '}
            Name and Description for the feed or provide a custom title and
            description for your video in the feed.
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoSettings;
