import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { SubscriptionType } from 'Colugo/interfaces/subscriptions/ISubscription';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import EmberPlanBox from 'components/shared/EmberPlanBox';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export type EmberPlanDetails = {
  title: string;
  price: string;
  attributes: string[];
  key: SubscriptionType;
};

const planData = [
  {
    key: SubscriptionType.Essential,
    title: 'Ember Essentials',
    price: 'Free',
    attributes: ['Manage Business Listing', 'x1 Promotion', 'x1 Event']
  },
  {
    key: SubscriptionType.Plus,
    title: 'Ember Plus',
    price: '£10',
    attributes: [
      'Manage Business Listing',
      'x5 Promotion',
      'x5 Event',
      'Analytics',
      'Display Video on Business Listing & Ember Highlights Feed',
      'Display Video on Event Page(s) & Ember Highlights Feed',
      '500 Estimated Views'
    ]
  },
  {
    key: SubscriptionType.Premium,
    title: 'Ember Premium',
    price: '£30',
    attributes: [
      'Manage Business Listing',
      'x25 Promotion',
      'x25 Event',
      'Analytics',
      'Display Video on Business Listing & Ember Highlights Feed',
      'Display Video on Event Page(s) & Ember Highlights Feed',
      '3,500 Estimated Views',
      'Chat Support'
    ]
  }
];

type UpdateBusinessPlanRouteParams = {
  listingId: string;
};

function UpdateBusinessPlan() {
  const navigate = useNavigate();
  const { listingId } = useParams<UpdateBusinessPlanRouteParams>();
  const { businessOwnsClaimsRequests } = useContext(ManagerContext);

  const listing = businessOwnsClaimsRequests.owns.find(
    (br) => br.id === listingId
  );

  const selected =
    listing?.subscription?.subscriptionType ?? SubscriptionType.Essential;
  return (
    <div className="flex flex-col h-screen bg-theme1">
      <CustomFormHeader
        title={'Upgrade Business Plan'}
        onPressClose={() => navigate(-1)}
      />
      <div className="justify-center mx-auto mt-24">
        <div className="flex gap-11">
          {planData.map((plan) => (
            <div key={plan.key}>
              <EmberPlanBox
                plan={plan}
                isSelected={selected === plan.key}
                selected={selected}
                onClickButton={(plan) => {
                  navigate(
                    `${
                      PuffinRoutes.PlanPaymentType.split(':')[0] + listingId
                    }?plan=${plan}`
                  );
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UpdateBusinessPlan;
