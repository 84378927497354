import { IAnalyticField } from 'Colugo/interfaces/common/IAnalyticField';
import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';

type Props = {
  analytics?: IAnalytics;
  topComponent?: JSX.Element;
  nOfDays?: number;
  isWithCollected?: boolean;
  onClick?: () => void;
};

export default function AnalyticsSnapshot(props: Props) {
  const {
    analytics,
    topComponent,
    nOfDays = 7,
    isWithCollected = true,
    onClick
  } = props;

  const verticalDivider = (
    <div className="border-l border-outline" style={{ height: '138px' }} />
  );

  return (
    <div
      className={`font-inter rounded-lg ${
        onClick
          ? 'cursor-pointer hover:shadow-lg transition-all duration-200'
          : ''
      }`}
      onClick={onClick}
    >
      <div className="flex flex-col bg-white rounded-lg border border-outline mt-4">
        {topComponent}
        <div className="flex flex-row px-2 py-6">
          {renderMetric(
            'Total Impressions',
            analytics?.impressions,
            nOfDays,
            isWithCollected ? '290px' : '390px'
          )}
          {verticalDivider}
          {renderMetric(
            'Total Clicks',
            analytics?.clicks,
            nOfDays,
            isWithCollected ? '290px' : '390px'
          )}
          {verticalDivider}
          {renderMetric(
            'Click-through rate',
            {
              ...analytics?.clickThroughRate,
              count: Number(
                ((analytics?.clickThroughRate.count || 0) * 100).toFixed(0)
              )
            },
            nOfDays,
            isWithCollected ? '290px' : '390px',
            true
          )}
          {isWithCollected && verticalDivider}
          {isWithCollected &&
            renderMetric('Total Saves', analytics?.collects, nOfDays, '290px')}
        </div>
      </div>
    </div>
  );
}

export function renderMetric(
  name: string,
  analyticField: IAnalyticField | undefined,
  nOfDays: number,
  width: string,
  isPercentage: boolean = false
) {
  return (
    <div className="flex flex-col px-4 font-inter" style={{ width: width }}>
      <span className="text-onSurfaceVariant font-semibold text-lg">
        {name}
      </span>
      <div className="flex flex-row mt-12">
        <span className="text-4xl">
          {(analyticField?.count ? analyticField.count.toString() : '0') +
            (isPercentage ? '%' : '')}
        </span>
        <div className="flex-grow" />
        <div className="flex flex-col">
          {renderDelta(analyticField?.delta)}
          <span className="font-normal text-sm text-onSurfaceVariant">
            From {getFromDateString(nOfDays)}
          </span>
        </div>
      </div>
    </div>
  );
}

export function getFromDateString(nOfDays: number) {
  return new Date(
    Date.now() - nOfDays * 24 * 60 * 60 * 1000
  ).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
}

export function renderDelta(delta: number | undefined) {
  delta = delta || 0;
  const deltaString = delta === 0 ? 'N/A' : formatNumber(100 * delta) + '%';
  return (
    <div
      className={`flex flex-row ${delta > 0 && 'text-success'} ${
        delta < 0 && 'text-error1'
      }`}
    >
      {delta > 0 && <BiSolidUpArrow size={10} className="mt-1.5" />}
      {delta < 0 && <BiSolidDownArrow size={10} className="mt-1.5" />}
      <span
        className={`ml-1 text-sm font-bold ${
          delta === 0 ? 'text-onSurfaceVariant' : ''
        } `}
      >
        {deltaString}
      </span>
    </div>
  );
}

function formatNumber(value: number): number | string {
  return Number.isInteger(value) ? value : value.toFixed(1);
}
