import 'reflect-metadata';

import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { default as BusinessLayout } from 'components/business/Layout';
import NotFound from 'components/business/NotFound';
import BusinessAnalytics from 'components/business/dashboard/BusinessAnalytics';
import BusinessDetails from 'components/business/dashboard/BusinessDetails';
import BusinessHome from 'components/business/dashboard/BusinessHome';
import ManageBusiness from 'components/business/dashboard/ManageBusiness';
import ManageEvents from 'components/business/dashboard/ManageEvents';
import SubmitBusinessRequest from 'components/business/dashboard/SubmitBusinessRequest';
import SubmitClaim from 'components/business/dashboard/SubmitClaim';
import UpdateBusinessPlan from 'components/business/dashboard/UpdateBusinessPlan';
import BusinessClaims from 'components/dashboard/BusinessClaims';
import BusinessRequests from 'components/dashboard/BusinessRequests';
import DashboardContainer from 'components/dashboard/DashboardContainer';
import { default as AdminLayout } from 'components/dashboard/Layout';
import CreateEvent from 'components/events/CreateEvent';
import EditEventContainer from 'components/events/EditEventContainer';
import ImportEventsContainer from 'components/import/ImportEventsContainer';
import ImportListingsContainer from 'components/import/ImportListingsContainer';
import CreateListing from 'components/listings/CreateListing';
import EditListingContainer from 'components/listings/EditListingContainer';
import AccountCreated from 'components/onboarding/AccountCreated';
import ForgotPassword from 'components/onboarding/ForgotPassword';
import ResetPassword from 'components/onboarding/ResetPassword';
import SignIn from 'components/onboarding/SignIn';
import SignUp from 'components/onboarding/SignUp';
import VerifyEmail from 'components/onboarding/VerifyEmail';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import EditSuggestionContainer from 'components/suggestions/EditSuggestionContainer';
import ListingSuggestionBucketsContainer from 'components/suggestions/ListingSuggestionBucketsContainer';
import SuggestionBucketsContainer from 'components/suggestions/SuggestionBucketsContainer';
import { AuthContext } from 'provider/auth/authProvider';
import EventsProvider from 'provider/events/eventProvider';
import FilterGroupProvider from 'provider/filterGroups/filterGroupsProvider';
import ListingsProvider from 'provider/listings/listingsProvider';
import ManagerProvider from 'provider/manager/managerProvider';
import PromotionsProvider from 'provider/promotions/promotionsProvider';
import TagsProvider from 'provider/tags/tagsProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PlanPaymentType from 'components/business/dashboard/PlanPaymentType';
import PlanOrderSummary from 'components/business/dashboard/PlanOrderSummary';
import PaymentProvider from 'provider/payment/PaymentProvider';
import PaymentCanceled from 'components/business/dashboard/PaymentCanceled';
import PaymentSuccess from 'components/business/dashboard/PaymentSuccess';

const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<AdminLayout />}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          element={<DashboardContainer />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path={PuffinRoutes.EditListing}
          element={<EditListingContainer />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path={PuffinRoutes.EditEvent}
          element={<EditEventContainer />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path={PuffinRoutes.ManageAccounts}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            element={<BusinessClaims />}
            errorElement={<ErrorBoundary />}
          />
          <Route
            path={PuffinRoutes.BusinessClaims}
            element={<BusinessClaims />}
            errorElement={<ErrorBoundary />}
          />
          <Route
            path={PuffinRoutes.BusinessRequests}
            element={<BusinessRequests />}
            errorElement={<ErrorBoundary />}
          />
        </Route>

        <Route
          path="*"
          element={<NotFound />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
      <Route
        path={PuffinRoutes.SuggestionsBucket}
        element={<SuggestionBucketsContainer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        index
        path={PuffinRoutes.CreateEvent}
        element={<CreateEvent />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        index
        path={PuffinRoutes.CreateListing}
        element={<CreateListing />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.ImportListings}
        element={<ImportListingsContainer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.ImportEvents}
        element={<ImportEventsContainer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.EditSuggestion}
        element={<EditSuggestionContainer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.ListingSuggestionBucket}
        element={<ListingSuggestionBucketsContainer />}
        errorElement={<ErrorBoundary />}
      />
      <Route path="*" element={<NotFound />} errorElement={<ErrorBoundary />} />
    </>
  )
);

const businessAuthenticatedRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<BusinessLayout />}
      errorElement={<ErrorBoundary />}
    >
      <Route
        index
        element={<BusinessHome />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.SubmitClaim}
        element={<SubmitClaim />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.ManagerEditEvent}
        element={<EditEventContainer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.ManagerCreateEvent}
        element={<CreateEvent />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.SubmitBusinessRequest}
        element={<SubmitBusinessRequest />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.ManageBusiness}
        element={<ManageBusiness />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.ManageEvents}
        element={<ManageEvents />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.BusinessDetails}
        element={<BusinessDetails />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.UpdateBusinessPlan}
        element={<UpdateBusinessPlan />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.PlanPaymentType}
        element={
          <PaymentProvider>
            <PlanPaymentType />
          </PaymentProvider>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.PlanOrderSummary}
        element={
          <PaymentProvider>
            <PlanOrderSummary />
          </PaymentProvider>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.PaymentSuccess}
        element={<PaymentSuccess />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.PaymentCanceled}
        element={<PaymentCanceled />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path={PuffinRoutes.Analytics}
        element={<BusinessAnalytics />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="*"
        element={<BusinessHome />}
        errorElement={<ErrorBoundary />}
      />
    </Route>
  )
);

const nonAuthenticatedRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        index
        path="/"
        element={<SignIn />}
        errorElement={<ErrorBoundary />}
      />
      <Route path={PuffinRoutes.SignUp} element={<SignUp />} />
      <Route path={PuffinRoutes.ForgotPassword} element={<ForgotPassword />} />
      <Route path={PuffinRoutes.ResetPassword} element={<ResetPassword />} />
      <Route path={PuffinRoutes.AccountCreated} element={<AccountCreated />} />
      <Route path={PuffinRoutes.VerifyEmail} element={<VerifyEmail />} />
    </>
  )
);

function App() {
  const { token } = useContext(AuthContext);
  const { user, isSuperAdmin } = useContext(UserContext);

  if (!token) {
    return (
      <>
        <ToastContainer
          position="bottom-right"
          closeOnClick
          draggable={false}
          transition={Zoom}
        />
        <RouterProvider router={nonAuthenticatedRouter} />
      </>
    );
  }

  if (!user) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />;
      </div>
    );
  }

  const toastContainer = (
    <ToastContainer
      position="bottom-right"
      closeOnClick
      draggable={false}
      transition={Zoom}
    />
  );
  return (
    <TagsProvider>
      <FilterGroupProvider>
        {isSuperAdmin ? (
          <ListingsProvider>
            <EventsProvider>
              <PromotionsProvider>
                {toastContainer}
                <RouterProvider router={adminRouter} />
              </PromotionsProvider>
            </EventsProvider>
          </ListingsProvider>
        ) : (
          <ManagerProvider>
            {toastContainer}
            <RouterProvider router={businessAuthenticatedRouter} />
          </ManagerProvider>
        )}
      </FilterGroupProvider>
    </TagsProvider>
  );
}

export default App;
