import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import { IListingFromSearch } from 'Colugo/interfaces/listing/IListing';
import BusinessRequestOperations from 'Colugo/operations/businessRequest/BusinessRequestOperations';
import ClaimOperations from 'Colugo/operations/claims/ClaimOperations';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import EmptyCard from 'components/shared/EmptyCard';
import HintBox from 'components/shared/HintBox';
import ImprovedInput from 'components/shared/ImprovedInput';
import ImprovedTooltip from 'components/shared/ImprovedTooltip';
import ListingCard, { ListingCardType } from 'components/shared/ListingCard';
import { ReactComponent as HintActive } from 'images/dashboard/infoActive.svg';
import { ReactComponent as HintInactive } from 'images/dashboard/infoInactive.svg';
import { ReactComponent as SearchIcon } from 'images/dashboard/searchIcon.svg';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import BusinessResultsList from './BusinessResultsList';

export enum SearchedEntityType {
  Listing,
  Event,
  Promotion
}

export enum HintType {
  Business = 'business',
  Request = 'request'
}

const listingOperations = container.resolve(ListingOperations);
const claimOperations = container.resolve(ClaimOperations);
const businessRequestOperations = container.resolve(BusinessRequestOperations);

function ManageBusiness() {
  // State
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isBusinessHintOpen, setIsBusinessHintOpen] = useState(false);
  const [isRequestHintOpen, setIsRequestHintOpen] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [localListings, setLocalListings] = useState<IListingFromSearch[]>([]);
  const { isSuperAdmin } = useContext(UserContext);
  const { hasSearchedForBusiness, setHasSearchedForBusiness } =
    useContext(ManagerContext);
  const { errorToast } = useErrorToast();
  const navigate = useNavigate();

  const {
    businessOwnsClaimsRequests,
    isError,
    isLoading,
    selectedOwnedId,
    setSelectedOwnedId,
    mutateOwnsClaimsRequests,
    isListingLoading
  } = useContext(ManagerContext);

  useEffect(() => {
    if (
      businessOwnsClaimsRequests?.owns &&
      businessOwnsClaimsRequests.owns.length > 0 &&
      businessOwnsClaimsRequests.owns[0].id &&
      (!selectedOwnedId || selectedOwnedId === '')
    ) {
      setSelectedOwnedId(businessOwnsClaimsRequests.owns[0].id);
      navigate('/');
    }
  }, [
    businessOwnsClaimsRequests.owns,
    navigate,
    selectedOwnedId,
    setSelectedOwnedId
  ]);

  const onSearchListingsAsync = useCallback(
    async (query: string) => {
      if (!query) {
        return;
      }
      const { data, error } = await listingOperations.searchListingsAsync(
        query,
        5
      );

      setIsSearchMode(true);
      if (!hasSearchedForBusiness) {
        setHasSearchedForBusiness(true);
      }
      if (!data || error) {
        setIsSearchMode(false);
        errorToast('An error occured, please try again');
        return;
      }
      setLocalListings(data);
    },
    [
      setLocalListings,
      setIsSearchMode,
      errorToast,
      hasSearchedForBusiness,
      setHasSearchedForBusiness
    ]
  );

  const debouncedSearchListingsAsync = useAsyncDebounce(
    async (searchQuery: string) => {
      return await onSearchListingsAsync(searchQuery);
    },
    200
  );

  useEffect(() => {
    const searchForListingsAsync = async () => {
      if (searchQuery.length >= 2) {
        await debouncedSearchListingsAsync(searchQuery);
        setIsSearchMode(true);
      } else {
        setIsSearchMode(false);
      }
    };
    searchForListingsAsync();
  }, [searchQuery, debouncedSearchListingsAsync]);

  async function removeClaimRequestAsync(id?: string) {
    if (!id) {
      errorToast('No claim found to remove.');
      return;
    }
    const { error } = await claimOperations.deleteAsync(id);

    if (error) {
      errorToast('An error occured, please try again');
      return;
    }
    const updatedClaims = businessOwnsClaimsRequests.claims.filter(
      (c) => c.id !== id
    );
    mutateOwnsClaimsRequests({
      ...businessOwnsClaimsRequests,
      claims: updatedClaims
    });
    toast.success('Claim deleted', {
      hideProgressBar: true,
      closeButton: (
        <IoClose color={'#FFFFFF'} size={24} style={{ marginTop: '6px' }} />
      )
    });
  }

  async function removeBusinessRequestAsync(id: string) {
    const { error } = await businessRequestOperations.deleteAsync(id);

    if (error) {
      errorToast('An error occured, please try again');
      return;
    }
    const updatedRequests = businessOwnsClaimsRequests.requests.filter(
      (r) => r.id !== id
    );
    mutateOwnsClaimsRequests({
      ...businessOwnsClaimsRequests,
      requests: updatedRequests
    });
    toast.success('Business request deleted', {
      hideProgressBar: true,
      closeButton: (
        <IoClose color={'#FFFFFF'} size={24} style={{ marginTop: '6px' }} />
      )
    });
  }

  function handleOnChange(e: InputFormEvent) {
    setSearchQuery(e.currentTarget.value);
  }

  function handleCancelSearch() {
    setSearchQuery('');
    setIsSearchMode(false);
  }

  function toggleHint(type) {
    switch (type) {
      case HintType.Business:
        setIsBusinessHintOpen(!isBusinessHintOpen);
        break;
      default:
        setIsRequestHintOpen(!isRequestHintOpen);
        break;
    }
  }

  const hasClaimsOrRequests =
    (businessOwnsClaimsRequests?.claims &&
      businessOwnsClaimsRequests.claims.length > 0) ||
    (businessOwnsClaimsRequests?.requests &&
      businessOwnsClaimsRequests.requests.length > 0);

  const ownsBusinesses =
    businessOwnsClaimsRequests?.owns &&
    businessOwnsClaimsRequests.owns.length > 0;

  if (isListingLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-row w-full overflow-y-auto">
      <div
        className="mt-10 pb-10 w-full h-full relative mx-auto"
        style={{ width: '914px' }}
      >
        <p className="font-inter text-background text-3xl font-bold">
          Manage Businesses
        </p>
        <ImprovedInput
          value={searchQuery}
          onChange={handleOnChange}
          className="h-full py-4 px-4 focus:outline-none"
          wrapperClassName="border mt-3 rounded-xl bg-white flex-row items-center focus:border-onSurface px-2 mb-3"
          placeHolder="Search Businesses"
          prependJsx={
            <div className="flex items-center flex-col h-full justify-center ml-2">
              <SearchIcon className="text-onSurface" />
            </div>
          }
          appendJsx={
            <div className="flex items-center flex-col h-full justify-center mr-2 cursor-pointer">
              {searchQuery.length > 0 ? (
                <div onClick={handleCancelSearch}>
                  <IoClose
                    size={20}
                    className="text-onSurface cursor-pointer"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          }
        />
        {isSearchMode ? (
          <div className="h-auto">
            <BusinessResultsList results={localListings} />
          </div>
        ) : (
          <>
            <div className="flex justify-between mb-4">
              <p className="font-inter text-onSurface font-bold">
                Your Businesses
              </p>
              <button onClick={() => toggleHint(HintType.Business)}>
                {isBusinessHintOpen ? <HintActive /> : <HintInactive />}
              </button>
            </div>
            {isBusinessHintOpen && (
              <HintBox
                header="Welcome to Ember Business. Let’s get started!"
                onCloseHint={() => toggleHint(HintType.Business)}
              >
                <ol type="1">
                  <li className="text-onSurface">
                    1. Start by searching for your business in the search bar
                    above.
                  </li>
                  <li className="text-onSurface">
                    2. If your business already exists on our platform, click
                    the claim button next to it.
                  </li>
                  <li className="text-onSurface">
                    3. If your search returns no results, you can then submit a
                    new business request.
                  </li>
                </ol>
              </HintBox>
            )}
            {ownsBusinesses && !isLoading ? (
              <div className="h-auto w-full">
                {businessOwnsClaimsRequests?.owns.map((ownedListing) => (
                  <ListingCard
                    listing={ownedListing}
                    key={ownedListing.id}
                    styles={{ width: '100%' }}
                    containerClassNames="py-4 border-0 mb-3 justify-between items-center"
                    isBusinessCard
                    type={ListingCardType.ClaimRequest}
                    onClickBusinessBtn={() => {
                      if (ownedListing.id) {
                        setSelectedOwnedId(ownedListing.id);
                        navigate('/');
                      }
                    }}
                    isSelected={ownedListing.id === selectedOwnedId}
                    isManageMode
                  />
                ))}
              </div>
            ) : (
              <EmptyCard
                text="Please search above to add your business."
                isLoading={isLoading}
                isError={isError}
              />
            )}
            <div className="flex justify-between my-4">
              <p className="font-inter text-onSurface font-bold">
                Requests & Claims
              </p>
              <button onClick={() => toggleHint(HintType.Request)}>
                {isRequestHintOpen ? <HintActive /> : <HintInactive />}
              </button>
            </div>
            {isRequestHintOpen && (
              <HintBox onCloseHint={() => toggleHint(HintType.Request)}>
                <p className="w-5/6">
                  You can claim your business if it already exists on our
                  platform, enabling you to have ownership and editing rights
                  for said business. However, if your business does not exist,
                  please submit a request to add it our platform.
                </p>
              </HintBox>
            )}
            <div>
              {hasClaimsOrRequests && !isLoading ? (
                <div className="h-auto">
                  {businessOwnsClaimsRequests?.claims.map((claim) => {
                    return (
                      claim && (
                        <ListingCard
                          listing={claim.listing}
                          key={claim.id}
                          styles={{ width: '100%' }}
                          containerClassNames="py-4 border-0 mb-3 justify-between items-center"
                          isBusinessCard
                          claimRequestStatus={claim.status}
                          type={ListingCardType.ClaimRequest}
                          rejectionReason={claim.rejectionReason}
                          removeClaimOrBusinessRequest={() =>
                            removeClaimRequestAsync(claim?.id)
                          }
                        />
                      )
                    );
                  })}
                  {businessOwnsClaimsRequests?.requests.map(
                    (businessRequest, i) => (
                      <ListingCard
                        listing={businessRequest.listing}
                        key={i}
                        styles={{ width: '100%' }}
                        containerClassNames="py-4 border-0 mb-3 justify-between items-center"
                        isBusinessCard
                        claimRequestStatus={businessRequest.status}
                        type={ListingCardType.BusinessRequest}
                        rejectionReason={businessRequest.rejectionReason}
                        removeClaimOrBusinessRequest={() =>
                          removeBusinessRequestAsync(businessRequest.id!)
                        }
                      />
                    )
                  )}
                </div>
              ) : (
                <EmptyCard
                  text="Please search above to request or claim your business."
                  isLoading={isLoading}
                  isError={isError}
                  error="Unable to fetch Pending Request & Claims, "
                />
              )}
            </div>
          </>
        )}
        {(!hasSearchedForBusiness || isSearchMode) && (
          <div
            className="absolute top-0 transition-all duration-200"
            style={{
              marginTop: isSearchMode ? '118px' : '48px',
              left: '925px'
            }}
          >
            <ImprovedTooltip
              title={
                isSearchMode
                  ? 'Claim your business or submit a new business request'
                  : 'Start by searching for your business!'
              }
              subtitle={
                isSearchMode
                  ? 'If your business already exists on our platform, click the claim button next to it. If your search returns no results, you can then submit a new business request.'
                  : 'Please use the search bar to search for your business on the ember platform.'
              }
              isSuperAdmin={isSuperAdmin}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ManageBusiness;
